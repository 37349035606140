import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Exhibition = () => {
  const [visibleText, setVisibleText] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const texts = [
    '메타스테이션 디지털낭만 전시회에 오신것을 환영합니다!',
    '저를 눌러서 다양한 메뉴를 확인할 수 있습니다.'
  ];

  useEffect(() => {
    const typingInterval = setInterval(() => {
      if (currentIndex < texts[visibleText].length) {
        setDisplayedText((prev) => prev + texts[visibleText][currentIndex]);
        setCurrentIndex((prev) => prev + 1);
      } else {
        clearInterval(typingInterval);
        setTimeout(() => {
          setDisplayedText('');
          setCurrentIndex(0);
          setVisibleText((prev) => (prev + 1) % texts.length);
        }, 2000);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, [currentIndex, visibleText]);

  const handleImageClick = (index) => {
    setSelectedImage(index);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const handleCharacterClick = () => {
    console.log('Character clicked!', isMenuOpen);
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    { title: '전시회 소개', path: '/about' },
    { title: '작품 감상 및 AI 도슨트', path: '/docent' },
    { title: 'AI와 작품 만들기', path: '/create' }
  ];

  return (
    <div className="relative min-h-screen bg-black">
      {/* 테두리 효과 */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[#101010]/50"></div>
        <div 
          className="absolute inset-[40px] bg-black" 
          style={{
            clipPath: `path('M40 0C20 0 0 20 0 40v calc(100% - 80px)C0 calc(100% - 20) 20 100% 40 100%h calc(100% - 80px)C calc(100% - 20) 100% 100% calc(100% - 20) 100% calc(100% - 40)v-calc(100% - 80px)C100% 20 calc(100% - 20) 0 calc(100% - 40) 0')`,
            borderRadius: "40px"
          }}
        >
          {/* 3D 배경 효과 */}
          <div className="mt-[100px] inset-0 overflow-hidden flex flex-col justify-center items-center">
            <div className="w-full p-4 overflow-hidden">
              {/* 왼 번째 줄: 왼쪽에서 오른쪽으로 */}
              <div className="flex flex-nowrap gap-4 animate-slideRight mb-4"
                   style={{ animation: 'slideRight 30s linear infinite' }}>
                {[...Array(20)].map((_, i) => (
                  <div 
                    key={`right-slide-${i}`}
                    className="w-[200px] h-[200px] flex-shrink-0 rounded-lg bg-white"
                    onClick={() => handleImageClick((i % 10) + 1)}
                  >
                    <img 
                      src={`/assets/slide/${(i % 10) + 1}.png`} 
                      alt={`Slide ${i + 1}`}
                      className="w-full h-full object-cover rounded-lg"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/assets/meta-favicon.png';
                      }}
                    />
                  </div>
                ))}
              </div>

              {/* 두 번째 줄: 오른쪽에서 왼쪽으로 */}
              <div className="flex flex-nowrap gap-4 animate-slideLeft mb-4"
                   style={{ animation: 'slideLeft 30s linear infinite' }}>
                {[...Array(20)].map((_, i) => (
                  <div 
                    key={`left-slide-${i}`}
                    className="w-[200px] h-[200px] flex-shrink-0 rounded-lg bg-white"
                    onClick={() => handleImageClick((i % 10) + 11)}
                  >
                    <img 
                      src={`/assets/slide/${(i % 10) + 11}.png`} 
                      alt={`Slide ${i + 1}`}
                      className="w-full h-full object-cover rounded-lg"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/assets/meta-favicon.png';
                      }}
                    />
                  </div>
                ))}
              </div>

              {/* 세 번째 줄: 왼쪽에서 오른쪽으로 */}
              <div className="flex flex-nowrap gap-4 animate-slideRight mb-4"
                   style={{ animation: 'slideRight 30s linear infinite' }}>
                {[...Array(20)].map((_, i) => (
                  <div 
                    key={`bottom-slide-${i}`}
                    className="w-[200px] h-[200px] flex-shrink-0 rounded-lg bg-white"
                    onClick={() => handleImageClick((i % 10) + 21)}
                  >
                    <img 
                      src={`/assets/slide/${(i % 10) + 21}.png`} 
                      alt={`Slide ${i + 1}`}
                      className="w-full h-full object-cover rounded-lg"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/assets/meta-favicon.png';
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 선택된 이미지 확대 표시 */}
      {selectedImage && (
        <div className="fixed inset-0 flex flex-col justify-center items-center bg-black bg-opacity-50 z-50" onClick={handleClose}>
          <div className="relative" style={{ perspective: '1000px' }}>
            <div 
              className="relative w-[500px] h-[500px]" 
              style={{ 
                transformStyle: 'preserve-3d',
                transition: 'transform 0.6s'
              }}
            >
              <img 
                src={`/assets/slide/${selectedImage}.png`} 
                alt="Loading..."
                className="absolute w-full h-full object-contain"
                style={{ 
                  backfaceVisibility: 'hidden',
                  transform: 'rotateY(180deg)'
                }}
              />
              <img 
                src={`/assets/slide/${selectedImage}.png`} 
                //src={`/assets/meta-favicon.png`} 
                alt={`Selected Slide ${selectedImage}`}
                className="absolute w-full h-full object-contain"
                style={{ 
                  backfaceVisibility: 'hidden'
                }}
                onLoad={(e) => {
                  e.target.parentElement.style.transform = 'rotateY(180deg)';
                }}
              />
            </div>
          </div>
          <p className="text-white mt-4 text-sm opacity-70 custom-font">배경을 누르면 창이 닫힙니다.</p>
        </div>
      )}

      <div className="relative z-10">
        {/* 네비게이션 바 */}
        <nav className="p-4">
          <div className="flex justify-between items-center">
            
            {/* META 로고 */}
            <div className="absolute left-1/2 top-[75px] transform -translate-x-1/2">
              <img 
                src="/assets/Group 109-1.png" 
                alt="META Logo" 
                className="h-12"
              />
            </div>

            {/* <div className="flex z-10">
              <button 
                className="bg-white w-10 h-10 rounded-full p-2 fixed bottom-4 right-4 shadow-lg"
              >
                ☰
              </button>
            </div> */}
          </div>
        </nav>

        {/* About Us 카드 */}
        {/* <div className="absolute right-8 top-1/2 bg-white rounded-2xl p-8 max-w-sm backdrop-blur-lg shadow-lg">
          <div className="flex items-center mb-4">
            <span className="mr-3 text-xl">👥</span>
            <h3 className="font-bold text-lg">About Us</h3>
          </div>
          <p className="text-gray-600 mb-6 leading-relaxed">
            Our journey began with a shared vision among a group of tech enthusiasts, brought together by the idea of revolutionizing the digital landscape.
          </p>
          <button className="text-blue-600 text-xl hover:translate-x-2 transition-transform">→</button>
        </div> */}
                      <div className="fixed bottom-[240px] right-4 bg-white p-2 rounded shadow-lg floating custom-font">
                <span className="text-black">{displayedText}</span>
              </div>
 <div className="fixed bottom-[4px] right-[10px] justify-center z-10">
                  <img 
                    src="/assets/character.png" 
                    alt="Exhibition Image" 
                    className="w-[250px] max-w-[500px] floating cursor-pointer"
                    onClick={handleCharacterClick}
                  />
                </div>

        {/* 메뉴 UI를 별도로 분리하여 화면 중앙에 배치 */}
        {isMenuOpen && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-xl p-4 w-[300px] animate-fadeIn">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold text-black custom-font">메뉴</h2>
                <button 
                  onClick={handleCharacterClick} 
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>
              {menuItems.map((item, index) => (
                <div
                  key={index}
                  className="py-3 px-4 hover:bg-gray-100 cursor-pointer rounded text-black transition-colors custom-font"
                  onClick={() => navigate(item.path)}
                >
                  {item.title}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* 메인 텍스트 섹션 - 위치 변경 및 스타일 수정 */}
        <div className="fixed bottom-[0px]">
          <div className="relative">
            <div className="p-10 flex flex-col">
               

                {/* 날짜와 프로젝트명
                <div className="flex flex-col items-start bg-[#101010]/50 p-3 pl-[30px] pr-[100px] rounded-tr-[40px] w-full max-w-fit">
                    <span className="text-white text-lg md:text-xl">
                    2024 META-PROJECT
                    </span>
                    <span className="text-white mt-2 text-base md:text-lg">
                    2024. 12. 20. - 2025. 01. 30.
                    </span>
                </div> */}

                {/* 메인 텍스트 */}
                <div className="flex flex-col">
                    
                    <span className="bg-[#101010]/50 p-2 md:p-5 lg:p-7 pr-[80px] text-white inline-block rounded-tr-[70px] rounded-bl-[45.1px] md:rounded-bl-[39.1px] lg:rounded-bl-[39.1px] w-fit">
                        <div className="flex items-center gap-4">
                            <h1 className="text-4xl md:text-6xl lg:text-5xl font-bold leading-tight custom-font">
                                Digital ROMANCE
                            </h1>
                        </div>
                    </span>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exhibition; 