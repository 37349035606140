import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { QrReader } from 'react-qr-reader'; // QR 스캐너 라이브러리 사용
import data from '../data/Data'; // 작품 데이터 가져오기

const Docent = () => {
  const [isScanning, setIsScanning] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  // 컴포넌트 마운트 시 자동으로 스캐너 활성화
  useEffect(() => {
    setIsScanning(true);
  }, []);

  const handleScan = (result) => {
    console.log("QR 스캔 결과:", result); // 디버깅용 로그 추가
    if (result?.text) {
      try {
        // 전체 URL을 그대로 사용하거나 필요한 부분만 추출
        const url = result.text;
        console.log("감지된 URL:", url); // 디버깅용 로그 추가
        
        // URL에서 직접 ID를 추출하거나 전체 URL을 사용
        if (url.includes('/artwork/')) {
          const artworkId = url.split('/artwork/')[1];
          setIsScanning(false);
          navigate(`/docent/artwork/${artworkId}`);
        } else {
          // URL이 아닌 경우 직접 ID로 처리
          const artworkId = url;
          setIsScanning(false);
          navigate(`/docent/artwork/${artworkId}`);
        }
      } catch (error) {
        console.error('QR 코드 처리 중 오류:', error);
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query) {
      const results = Object.values(data.artworks).filter(artwork =>
        artwork.title.toLowerCase().includes(query.toLowerCase()) ||
        artwork.artist.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  // 상단에 로고 추가
  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-500 via-blue-500 to-indigo-600 p-8">
      <div className="flex justify-center mb-[20px]">
        <img 
          src="/assets/Group 109-1.png" 
          alt="META Logo" 
          className="h-12"
        />
      </div>
      <div className="max-w-4xl mx-auto">
        {/* 상단 네비게이션 */}
        <div className="mb-8">
          <button 
            onClick={() => navigate('/exhibition')}
            className="text-white hover:text-gray-200"
          >
            ← 돌아가기
          </button>
        </div>

        <div className="bg-white rounded-2xl shadow-xl p-6">
          <h1 className="text-2xl font-bold text-gray-900 mb-6 custom-font">작품 감상 및 AI 도슨트</h1>

          {isScanning ? (
            <div className="mb-6">
              <div style={{ position: 'relative', width: '100%', paddingTop: '100%', maxWidth: '400px', margin: '0 auto' }}>
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                  <QrReader
                    constraints={{
                      facingMode: 'environment'
                    }}
                    onResult={(result, error) => {
                      if (result) {
                        console.log("QR 인식됨:", result);
                        handleScan(result);
                      }
                      if (error) {
                        console.error("QR 에러:", error);
                      }
                    }}
                    videoStyle={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    videoContainerStyle={{ width: '100%', height: '100%' }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center mb-6">
              <p className="text-gray-600 mb-4 custom-font">QR 코드를 스캔하여 작품을 감상하세요.</p>
              <button 
                onClick={() => setIsScanning(true)}
                className="bg-blue-500 text-white px-6 py-3 rounded-lg shadow hover:bg-blue-600"
              >
                카메라 열기
              </button>
            </div>
          )}

          {/* 검색바 추가 */}
          <div className="mt-8 pr-[25px]">
            <h2 className="text-xl font-semibold text-gray-900 mb-4 custom-font">작품 검색</h2>
            <div className="max-w-md mx-auto">
              <input 
                type="text"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="작품 제목 또는 작가를 입력하세요"
                className="w-full p-3 border border-gray-300 rounded-lg custom-font"
              />
              <div className="mt-4">
                {searchResults.map((artwork) => (
                  <div 
                    key={artwork.id} 
                    className="mb-2 p-2 hover:bg-gray-100 rounded cursor-pointer"
                    onClick={() => navigate(`/docent/artwork/${artwork.id}`)}
                  >
                    <p className="text-gray-800 custom-font">{artwork.title} - {artwork.artist}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Docent; 