import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import data from '../data/Data';

const Create = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const artworks = location.state?.artworks || data.artworks;
  const { objects } = data;
  const [selectedArtwork, setSelectedArtwork] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [resultImage, setResultImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleArtworkClick = (artwork) => {
    setSelectedArtwork(artwork);
  };

  const handleObjectClick = (object) => {
    setSelectedObject(object);
  };

  const handleImageSelect = (image) => {
    setSelectedImage(image);
  };

  const handleRequestAI = async () => {
    if (!selectedArtwork || !selectedObject) {
      alert('작품과 AI 스타일을 모두 선택해주세요.');
      return;
    }

    setIsLoading(true);
    try {
      const formData = new FormData();
      
      const imageResponse = await fetch(selectedImage || selectedArtwork.images[0]);
      const imageBlob = await imageResponse.blob();
      formData.append('image', imageBlob, 'image.jpg');
      formData.append('word', selectedObject.tag);

      const response = await fetch('https://cnitsstudio-api.com:5004/api/generate-image', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      
      if (data.success) {
        const fullImagePath = `https://cnitsstudio-api.com:5004${data.output_image_path}`;
        setResultImage(fullImagePath);
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      console.error('AI 요청 중 오류 발생:', error);
      alert('AI 작품 생성 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-500 via-blue-500 to-indigo-600">
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-6">
          <button 
            onClick={() => navigate(-1)}
            className="flex items-center text-white hover:text-gray-200 transition-colors"
          >
            <span className="mr-2">←</span> 돌아가기
          </button>
        </div>

        <div className="bg-white rounded-lg shadow-lg p-6">
          <h1 className="text-3xl font-bold mb-4 text-center custom-font">
            AI와 함께 작품 만들기
          </h1>
          <p className="text-gray-600 text-center mb-8 custom-font">
            작가 분의 작품과 일상 속 물건들을 결합하여 AI 작가가 새로운 예술 작품을 만들어냅니다.<br />
            여러분만의 특별한 예술 작품을 감상해보세요.
          </p>
          
          {selectedArtwork ? (
            <div className="space-y-6">
              <button 
                onClick={() => setSelectedArtwork(null)}
                className="text-blue-600 hover:text-blue-800 mb-4"
              >
                ← 목록으로 돌아가기
              </button>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* 원본 작품 */}
                <div className="space-y-4">
                  <h2 className="text-xl font-semibold custom-font">원본 작품</h2>
                  <img 
                    src={selectedImage || selectedArtwork.images[0]} 
                    alt={selectedArtwork.title}
                    className="w-full rounded-lg shadow-md"
                  />
                  
                  <div className="grid grid-cols-4 gap-2">
                    {selectedArtwork.images.map((image, index) => (
                      <div 
                        key={index}
                        onClick={() => handleImageSelect(image)}
                        className={`cursor-pointer transition-all duration-200 ${
                          (selectedImage || selectedArtwork.images[0]) === image 
                            ? 'ring-2 ring-blue-500 scale-105 shadow-lg' 
                            : 'hover:scale-105 hover:ring-2 hover:ring-blue-300'
                        }`}
                      >
                        <img 
                          src={image} 
                          alt={`${selectedArtwork.title} ${index + 1}`}
                          className={`w-full h-20 object-cover rounded-lg ${
                            (selectedImage || selectedArtwork.images[0]) === image 
                              ? 'brightness-110' 
                              : 'hover:brightness-105'
                          }`}
                        />
                      </div>
                    ))}
                  </div>
                  
                  <div className="space-y-2">
                    <h3 className="text-lg font-medium custom-font">{selectedArtwork.title}</h3>
                    <p className="text-gray-600 custom-font">{selectedArtwork.artist}</p>
                  </div>
                </div>

                {/* AI 작품 예시 */}
                <div className="space-y-4">
                  <h2 className="text-xl font-semibold custom-font">AI 작품 스타일</h2>
                  <div className="grid grid-cols-2 gap-4">
                    {Object.values(objects).map((object) => (
                      <div 
                        key={object.id} 
                        className={`relative group cursor-pointer aspect-square ${
                          selectedObject?.id === object.id ? 'ring-2 ring-blue-500' : ''
                        }`}
                        onClick={() => handleObjectClick(object)}
                      >
                        <img 
                          src={object.image} 
                          alt={object.title}
                          className="w-full h-full object-cover rounded-lg shadow-md transition-transform group-hover:scale-105"
                        />
                        <div className={`absolute inset-0 bg-black bg-opacity-40 rounded-lg flex items-center justify-center transition-opacity
                          ${selectedObject?.id === object.id ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'}`}>
                          <p className="text-white text-center text-sm font-medium px-2">
                            {object.title} ({object.tag})
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="text-center mt-8">
                <p className="text-gray-600 mb-3 custom-font">
                  작품 제작은 조금 시간이 소요될 수도 있습니다.
                </p>
                <button
                  onClick={handleRequestAI}
                  className="bg-blue-600 text-white px-8 py-3 rounded-lg shadow-lg hover:bg-blue-700 transition-colors custom-font"
                >
                  AI 작가에게 요청하기
                </button>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
              {Object.entries(artworks)
                .filter(([id]) => id !== '1')
                .map(([id, artwork]) => (
                  <div 
                    key={id} 
                    className="bg-gray-50 rounded-lg p-4 shadow hover:shadow-lg transition-shadow cursor-pointer"
                    onClick={() => handleArtworkClick(artwork)}
                  >
                    <img 
                      src={artwork.images[0]} 
                      alt={artwork.title}
                      className="w-full h-48 object-cover rounded-lg mb-4"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/assets/meta-favicon.png';
                      }}
                    />
                    <h3 className="text-xl font-semibold mb-2 custom-font">{artwork.title}</h3>
                    <p className="text-gray-600 mb-2 custom-font">{artwork.artist}</p>
                    <p className="text-sm text-gray-500 custom-font">
                      {artwork.description.length > 100 
                        ? artwork.description.substring(0, 100) + '...' 
                        : artwork.description}
                    </p>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>

      {/* 로딩 모달 */}
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-xl text-center">
            <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-600 mx-auto mb-4"></div>
            <p className="text-lg font-medium custom-font">AI가 작품을 생성하고 있습니다...</p>
          </div>
        </div>
      )}

      {/* 결과 모달 */}
      {resultImage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-2xl w-full mx-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold custom-font">AI 생성 결과</h3>
              <button 
                onClick={() => setResultImage(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>
            <img 
              src={resultImage} 
              alt="AI 생성 결과"
              className="w-full rounded-lg shadow-md mb-4"
            />
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => setResultImage(null)}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 custom-font"
              >
                닫기
              </button>
              <button
                onClick={() => {
                  // 여기에 이미지 저장 로직 가
                  window.open(resultImage, '_blank');
                }}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 custom-font"
              >
                저장하기
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Create; 