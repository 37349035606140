import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { useEffect } from 'react';

import Home from './pages/Home';
import Exhibition from './pages/Exhibition';
import About from './pages/About';
import Docent from './pages/Docent';
import ArtworkDetail from './pages/ArtworkDetail';
import { Navigate } from 'react-router-dom';
import Create from './pages/Create';

function App() {
  useEffect(() => {
    if (window.location.protocol === 'http:' && process.env.NODE_ENV === 'production') {
      window.location.replace(`https:${window.location.href.substring(5)}`);
    }
  }, []);

  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/exhibition' element={<Exhibition/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/docent' element={<Docent/>}/>
        <Route path='/docent/artwork/:artworkId' element={<ArtworkDetail/>}/>
        <Route path='/create' element={<Create/>}/>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
